import Vue from 'vue' //引入Vue
import Router from 'vue-router' //引入vue-router
Vue.use(Router)


//Vue全局使用Router
const originalPush = Router.prototype.push
Router.prototype.push =function push(location){
  return originalPush.call(this,location).catch(err =>err)
}
export default new Router({
  // mode:"history",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    }
  },

  routes: [ //配置路由，这里是个数组
    { //每一个链接都是一个对象
      path: '/', //链接路径
      redirect: '/index',
      component: () => import('@/view/pages/index.vue'),
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/view/pages/index.vue'),
    },
    {
      path: '/trending',
      name: 'trending',
      component: () => import('@/view/pages/trending.vue'),
    },
    {
      path: '/category',
      name: 'category',
      component: () => import('@/view/pages/categories.vue'),
    },
    {
      path: '/recharge',
      name: 'recharge',
      component: () => import('@/view/pages/recharge.vue'),
    },
    {
      path: '/details',
      name: 'details',
      component: () => import('@/view/pages/details.vue'),
    },
    {
      path: '/personal',
      name: 'personal',
      component: () => import('@/view/pages/personal.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/pages/login.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/view/pages/signup.vue'),
    },
    {
      path: '/serviceAgreement',
      name: 'serviceAgreement',
      component: () => import('@/view/info/serviceAgreement.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () => import('@/view/info/privacypolicy.vue'),
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: () => import('@/view/info/aboutus.vue'),
    },
    {
      path: '/fair',
      name: 'fair',
      component: () => import('@/view/info/fair.vue'),
    },
  ]
})

