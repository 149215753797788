<template>
  <div id="app">
    <HeaderTop :isFloat="isFloat" v-if="showHeader"></HeaderTop>
    <div class="main">
      <router-view v-if='isRouterAlive'></router-view>
    </div>
    <footer-model v-if="showFooter"></footer-model>
  </div>
</template>

<script>
import HeaderTop from './components/header.vue'
import footerModel from './components/footer.vue'
export default {
  name: 'App',
  components: {
    HeaderTop,
    // banner,
    footerModel
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data(){
    return{
      showHeader:true,
      isRouterAlive: true,
      showFooter:true,
    }
  },
  computed:{
    isFloat(){ return this.$store.state.isFloat}
  },
  created(){
    // this.$store.dispatch('getKeyword')
    // this.$store.dispatch('getCategories')
   
  },
  methods:{
    reload () {
      this.isRouterAlive = false
      let that = this
      this.$nextTick(function () {
        that.isRouterAlive = true
      })
    }
  },
  watch:{
    '$route':{
      handler(router){
        if(router.path == '/index'){
          this.$store.state.isFloat = false
          this.showHeader = true
          this.showFooter = true
        }else{
          this.showHeader = true
          this.showFooter = true
          this.$store.state.isFloat = true
          if(router.path == '/login' || router.path == '/signup' || router.path == '/personal' ){
            this.showHeader = false
             if(router.path == '/personal'){
              this.showHeader = true
              this.showFooter = false
            }
          }
          // if(router.path == '/personal'){
          //   this.showHeader = false
          //   this.showFooter = false
          // }
        }
       
      }
      
    }
  }
  
}
</script>

<style>
#app {
  font-family: Oxanium, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303030;
  background: #f7f7f9;
  display: flex;
  flex-direction: column;
    /* background-position: -600px 0px;
  background-attachment: fixed;
  min-height:100vh; */
  min-height: 100vh;
}
.main {
  /* margin: 0 auto; */
  flex:1;

  /* width: 100%; */
}
* {
  margin: 0;
  padding: 0;
}
</style>
