import Vue from 'vue'
import Vuex from 'vuex'
// import { Loading,Message } from 'element-ui'
// import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import game1 from '../assets/image/game1.png'
import game2 from '../assets/image/game2.png'
import game3 from '../assets/image/game3.png'
import game4 from '../assets/image/game4.png'
import game5 from '../assets/image/game5.png'
import game6 from '../assets/image/game6.png'
import game7 from '../assets/image/game7.png'
import game8 from '../assets/image/game8.png'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths:['isLogin','userInfo','userGames','videoGame']
   })],
    state: {
      isFloat:true,
      isLogin:false,
      userInfo:{},
      userGames:[],
      videoGame:[
        {name:'Space Huggers',url:'http://www.trovima.com/spacehuggers',image:game1,lock:true,icons:100,id:1},
        {name:'Black Hole Square',url:'http://www.trovima.com/blackholesquare/public',image:game2,lock:true,icons:100,id:2},
        {name:'Hexagon Fall',url:'http://www.trovima.com/hexagonfall',image:game3,lock:true,icons:100,id:3},
        {name:'Packa Bunchas',url:'http://www.trovima.com/packabunchas',image:game4,lock:true,icons:100,id:4},
        {name:'Ninja Action',url:'http://www.trovima.com/ninjaaction',image:game5,lock:true,icons:100,id:5},
        {name:'Air Battles',url:'http://www.trovima.com/airbattle',image:game6,lock:true,icons:100,id:6},
        {name:'Stick Soldier',url:'http://www.trovima.com/sticksoldier/',image:game7,lock:true,icons:100,id:7},
        {name:'Ancient Ore',url:'http://www.trovima.com/ancientore/',image:game8,lock:true,icons:100,id:8},
      ]
    },
  
    mutations: {
      setLogin(state,value){
        state.isLogin = value
      },
      setUserInfo(state,value){
        state.userInfo = value
      },
      setUserGames(state,value){
        state.userGames = value
      },
      setVideoGame(state,value){
        state.videoGame = value
      }
      
      
    },
    actions:{
      
    }
})
