<template>
  <div class="footer container">
    <div class="footer_container page_width">
     <div class="footer_thumb flex_sb">
        <div class="footer_thumb_left">
          <div class="copyright">Trovima is a comprehensive online marketplace for everything gaming-related. We are dedicated to innovating for the benefit of the gaming community.</div>
          <div class="bottom_tab text_center">
            <router-link class="tab_link" to="/serviceAgreement" style="padding-left:0;">Term of service</router-link>
            <router-link class="tab_link" to='/privacypolicy'>Privacy Policy</router-link>
            <router-link class="tab_link" to='/aboutus'>About Us</router-link>
            <!-- <router-link class="tab_link" to='/fair'>Probability Fairness</router-link> -->
          </div>
        </div>
        <div class='footer_thumb_left'>
          <div></div>
        </div>
     </div>
   
    </div>
  </div>
</template>

<script>
export default {
  name:'ASTRfooter',
}
</script>

<style lang='scss' scoped>
.footer {
  margin-top: 50px;
  background-color: rgb(255, 65, 48);
  .footer_container {
    padding-top:24px;
    padding-bottom:24px;
    color:#fff;
   
    .copyright {
      margin: 5px auto;
      font-size: 12px;
      
    }
    .bottom_tab {
      font-size: 12px;
      margin-top:4px;
      text-align: left;
      .tab_link {
        padding: 0 5px;
        border-left: 1px solid #ccc;
        cursor: pointer;
        color: #fff;
        text-decoration-line: none;
        &:hover {
          text-decoration-line: underline;
        }
        &:first-child {
          border: none;
        }
      }
    }
  }
}
</style>