import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.prototype.$axios = axios
// axios.defaults.baseURL = 'https://www.eqjncvb.com/v2.0/';
// axios.defaults.baseURL = 'https://www.salarypayway.com/v2.0/'

import store from './store/store'
import './style/global.scss'
import './style/reset.css'
import './style/font.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import {Message } from 'element-ui'
Vue.prototype.$Message = Message

Vue.use(ElementUI)
import 'animate.css';
Vue.config.productionTip = false
import router from './router/index'


router.beforeEach((_to,_form,next)=>{
  NProgress.start()
  next()
})
router.afterEach(()=>{
  NProgress.done()
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')